<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { authComputed } from "@/state/helpers";
import JsonExcel from "vue-json-excel";
import store from "@/state/store";
export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    if (userType === 1 || userType === 3) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "Self-assessment",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    ...authComputed,
  },
  components: {
    Layout,
    PageHeader,
    JsonExcel
  },
  mounted() {
    this.refreshTable();
  },
  methods: {
    refreshTable() {
       this.location = this.$route.params.location;
       //console.log(`Location ID: ${this.location} `)
       this.month = this.$route.params.month;
       this.year = this.$route.params.year;
       this.subcategory = this.$route.params.subcategory;
      const data = {
        location_id:parseInt(this.location),
        month: this.month.toString(),
        year: this.year.toString(),
        sub_category:parseInt(this.subcategory)
      };
      const url = `${this.ROUTES.masterList}`;
      this.loading = true;
      this.apiPost(url, data, "Generate Master List Report").then((res) => {
        if (res) {
          const result = res.data;
          for(let i = 0; i<result.length; i++){
            //console.log(result[i]);
            const obj = {
              sn:i+1,
              location: `${result[i].location?.location_name} (${result[i].location?.l_t6_code})`,
              regular_term: result[i].regular_term,
              limited_term: result[i].limited_term,
              short_term: result[i].short_term,
              total:result[i].total,
              percentage_workforce:result[i].percentage_workforce,
              cost_site:new Intl.NumberFormat().format(result[i].cost_per_site),
              percentage_cost_site:result[i].percentage_cost_per_site,
              female:result[i]?.female,
              male:result[i]?.male,
              new_hire:result[i]?.new_hire,
              relocate_from:result[i].relocate_from,
              relocate_to:result[i].relocate_to,
              exit:result[i].exit,
            }
            if( parseInt(this.location) === 0 ){
              this.locationName = "All Locations";
            }else{
              this.locationName = `${result[i].location?.location_name} (${result[i].location?.l_t6_code})`;
            }
            this.records.push(obj);
          }
          this.loading = false;
        }
      });
      this.newFields.forEach((field) => {
        let key = field;
        if (key === "sn") {
          this.jsonFields["S/N"] = key;
        } else if (key === "location") {
          this.jsonFields["LOCATION"] = key;
        } else if (key === "regular_term") {
          this.jsonFields["REGULAR TERM"] = key;
        } else if (key === "limited_term") {
          this.jsonFields["LIMITED TERM"] = key;
        } else if (key === "short_term") {
          this.jsonFields["SHORT TERM"] = key;
        } else if (key === "total") {
          this.jsonFields["TOTAL"] = key;
        } else if (key === "percentage_workforce") {
          this.jsonFields["PERCENTAGE WORKFORCE"] = key;
        } else if (key === "cost_site") {
          this.jsonFields["COST SITE"] = key;
        } else if (key === "percentage_cost_site") {
          this.jsonFields["PERCENTAGE PER COST SITE"] = key;
        } else if (key === "male") {
          this.jsonFields["MALE"] = key;
        }else if(key === "female"){
          this.jsonFields["FEMALE"] = key;
        }else if(key === "new_hire"){
          this.jsonFields["NEW HIRE"] = key;
        }else if(key === "relocate_from"){
          this.jsonFields["RELOCATE FROM"] = key;
        }else if(key === "relocate_to"){
          this.jsonFields["RELOCATE TO"] = key;
        }else if(key === "exit"){
          this.jsonFields["EXIT"] = key;
        }
      });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    monthText(val){
      switch(parseInt(val)){
        case 1:
          return "January";
        case 2:
          return "February";
        case 3:
          return "March";
        case 4:
          return "April";
        case 5:
          return "May";
        case 6:
          return "June";
        case 7:
          return "July";
        case 8:
          return "August";
        case 9:
          return "September";
        case 10:
          return "October";
        case 11:
          return "November";
        case 12:
          return "December";
      }
    },
    getSubcategoryText(val){
      switch(parseInt(val)){
        case 1:
          return "Corper";
        case 2:
          return "International Staff";
        case 3:
          return "National Staff";
        case 4:
          return "Regional";
        case 5:
          return "Interns";
        case 6:
          return "Consultant";
        case 7:
          return "Volunteer";
        case 8:
          return "Incentive Workers";
        case 9:
          return "Casual Workers";
        case 0:
          return "All";
      }
    },
  },
  data() {
    return {
      title: "Master List Report",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Master List Report",
          active: true,
        },
      ],
      counter:0,
      resultStatus:false,
      assessments: [],
      records:[],
      newFields:[
        "sn",
        "location",
        "regular_term",
        "limited_term",
        "short_term",
        "total",
        "percentage_workforce",
        "cost_site",
        "percentage_cost_site",
        "male",
        "female",
        "new_hire",
        "relocate_from",
        "relocate_to",
        "exit",
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "sn",
      sortDesc: false,
      loc: null,
      location: null,
      locationName: null,
      loading:false,
      fy:null,
      stage:null,
      month: null,
      year: null,
      selectedPeriod: null,
      jsonFields: {},
      filtered: [],
      subcategory:null,

      fields: [
        { key: "sn", label: "S/n", sortable: true },
        { key: "location", label: "Locations", sortable: true },
        { key: "regular_term", label: "Regular Term", sortable: true },
        { key: "limited_term", label: "Limited Term", sortable: true },
        { key: "short_term", label: "Short Term", sortable: true},
        { key: "total", label: "Total", sortable: true },
        { key: "percentage_workforce", label: "% of Workforce", sortable: false },
        { key: "cost_site", label: "Cost per site", sortable: false,},
        { key: "percentage_cost_site", label: "% Cost per site", sortable: true },
        { key: "male", label: "Male", sortable: true },
        { key: "female", label: "Female", sortable: true },
        { key: "new_hire", label: "New Hire", sortable: true },
        { key: "relocate_from", label: "Relocate From", sortable: true },
        { key: "relocate_to", label: "Relocate To", sortable: true },
        { key: "exit", label: "Exit", sortable: true },
      ],



    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <scale-loader v-if="apiBusy" />
    <div v-else class="row">
      <div class="col-12">

        <div class="card">
          <div class="card-body">
            <div class="p-3 bg-light mb-4 d-flex justify-content-between">
              <h5 class="font-size-14 mb-0">
                Master List Report
              </h5>
              <span class="font-size-12 text-success">
                <JsonExcel
                  style="cursor: pointer"
                  :data="records"
                  :fields="jsonFields"
                  :name="`fileName.xls`"
                >
                  Export to Excel
                </JsonExcel>
              </span>
            </div>

            <div class="row mt-4">
              <div class="col-md-12 col-lg-12 mb-5">
                <div class="btn-group">
                  <b-button
                    class="btn btn-secondary"
                    @click="$router.go(-1)"
                  >
                    <i class="mdi mdi-step-backward mr-2"></i>
                    Go Back
                  </b-button>
                </div>
                <p class="text-center" v-if="loading">Processing request....</p>
              </div>
              <div class="col-sm-12 col-md-6">
                <h5>
                   Master List Report for <small class="text-info">{{ monthText(this.month) }} {{ this.year }}. <span class="font-weight-semibold text-danger">Subcategory:</span> {{ getSubcategoryText(this.subcategory) }} <span class="text-danger font-weight-semibold">Location:</span> {{ this.locationName }} </small>
                </h5>
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                ref="donor-table"
                bordered
                selectable
                hover
                :items="records"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                select-mode="single"
              >
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
